<template>
  <div class="mb-[2rem] overflow-x-hidden">

    <div v-if="cartErrors.length > 0" class="mb-[1.5rem] grid grid-cols-1 gap-y-[0.5rem]">
      <Notification v-for="error in cartErrors" type="error">{{ t(`zeit_storefront.cart.errors.${error.messageKey}`) }} {{ error.name }}</Notification>
    </div>
    <div v-if="cartLastAdded.length > 0" class="mb-[1.5rem] grid grid-cols-1 gap-y-[0.5rem]">
      <Notification v-for="payloadItem in cartLastAdded" type="success"><span class="font-[700]">{{ t(`zeit_storefront.cart.success.added_product`) }}</span> {{ payloadItem?.payload?.productName }}</Notification>
    </div>

    <div class="grid grid-cols-1 gap-y-[0.5rem]">
      <a href="/warenkorb">
        <button type="button" class="flex w-full items-center justify-between rounded-[0.5rem] bg-[#222222] px-[1.5rem] py-[1rem] text-[1rem] font-[600] text-[#FFFFFF] transition-colors hover:bg-[#000000]">
          {{ t('zeit_storefront.cart.to_checkout') }}
          <svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" fill="white"/></svg>
        </button>
      </a>
      <button id="off-canvas-cart--success_shop-further-btn" type="button" data-close-slide-over-id="off-canvas-cart--success" class="modal-content--close flex w-full items-center justify-start rounded-[0.5rem] bg-[#F0F0F0] px-[1.5rem] py-[1rem] text-[1rem] font-[600] text-[#000000] transition-colors hover:bg-[#222222] hover:text-[#FFFFFF]">Weiter einkaufen</button>
    </div>

  </div>

  <div v-if="cartErrors.length === 0 && epoqRecommendationItems.length > 0">
    <div class="mb-[1rem] font-featured text-[1.5rem] font-[700] leading-[1.75rem]">{{ epoqRecommendations?.headline ?? t('zeit_storefront.components.epoq.cart_layer_headline_fallback') }}</div>
    <div class="grid grid-cols-2 gap-x-[0.5rem] gap-y-[1rem]">
      <a v-for="epoqRecommItem in epoqRecommendationItems"
         :href="epoqRecommItem['@feedbackurl'] ?? epoqRecommItem['@link']"
      >
        <ProductBox :name="epoqRecommItem['@name']"
                    :cover="{ media: { url: decodeURI(epoqRecommItem['@image_link']) } }"
                    :calculated-price="{ totalPrice: parseFloat(epoqRecommItem['@price']) }"
        />
      </a>
    </div>
  </div>

</template>

<script setup>
  import {cartCurrentErrors, cartLastAddedProducts} from "@store/globalStore.js";
  import {useEpoq} from "@shopware/thirdParty/epoq/index.js";
  import {useTranslations} from "@i18n/utils.js";

  import Notification from "@components/notifications/Notification.vue";
  import ProductBox from "@components/category/ProductBox.vue";
  import {computed, ref} from "vue";

  const {t} = useTranslations();
  const {getRecommendations} = useEpoq();

  const cartErrors = ref([]);
  const cartLastAdded = ref([]);

  const epoqRecommendations = ref(null);

  const epoqRecommendationItems = computed(() => {
    if (!Array.isArray(epoqRecommendations.value?.recommendations)) {
      return [];
    }

    return epoqRecommendations.value.recommendations;
  });

  cartCurrentErrors.subscribe((errors) => {
    cartErrors.value = Array.isArray(errors)
        ? errors.filter(error => error.messageKey !== 'payment-method-blocked') // The 'payment-method-blocked' error is unnecessary in the cart
        : [];
  });

  cartLastAddedProducts.subscribe(async (payload) => {
    cartLastAdded.value = payload;

    if (Array.isArray(payload) && payload.length > 0 && payload[0].payload?.productNumber) {
      /* epoqRecommendations.value = await getRecommendations('entrypagetopsellertheme', payload[0].payload?.productNumber); */
    }
  });
</script>